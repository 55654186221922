import React from "react";

import Logo from "../../images/logoFullDark.svg";

import { Wrapper, LogoImg } from "./fullLogo.styled";

export const FullLogo = () => (
  <Wrapper>
    <LogoImg src={Logo} />
  </Wrapper>
);
