import React, { Component } from "react";
import i18n from "i18next";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import styled from "styled-components";
import { replace } from "ramda";
import { Navigate } from "react-router";

import MainFooter from "../app/mainFooter/mainFooter.component";
import IconTable from "../userContext/iconTable";
import Logo from "../images/logoFullDark.svg";
import { Theme } from "../appConfig";
import { Path } from "../enums";
import { Color } from "../theme";
import { Input, ContentWrapper, Field } from "../UIElements";
import { breakpoints } from "../UIGlobals";

import { RegistrationFooter } from "./registrationFooter/registrationFooter.component";
import { FirstStep } from "./firstStep/firstStep.component";
import { UserRegistrationActions } from "./redux/userRegistration.reducer";
import * as UI from "./registerUI";
import { RegistrationConfirm } from "./password";
import { Container, LogoImg } from "./register.styled";

const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    cursor: "pointer",
    color: state.isFocused ? Color.white : Color.black,
    backgroundColor: state.isFocused ?  Color.black : Color.white,
    ":hover": {
      backgroundColor: Color.black,
      color: Color.white
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    cursor: "pointer",
  }),
  dropdownIndicator: (styles, { selectProps }) => {
    return ({
      ...styles,
      paddingBottom: "0",
      color: selectProps.errorTheme ? Color.red5 : styles.color,
      ":hover": {
        color: selectProps.errorTheme ? Color.red5 : styles[":hover"].color,
      },
    })
  },
  clearIndicator: (styles) => ({
    ...styles,
    paddingBottom: "0",
  }),
  indicatorSeparator: (styles, { selectProps }) => ({
    ...styles,
    margin: "11px 0 4px",
    backgroundColor: selectProps.errorTheme ? Color.red5 : styles.backgroundColor,
  }),
  control: (styles) => ({
    ...styles,
    border: "0",
    boxShadow: "none",
    ":hover": {
      border: "0"
    },
    backgroundColor: Color.transparent,
  }),
  placeholder: (styles, { selectProps }) => {
    return ({
      ...styles,
      marginLeft: "0",
      color: selectProps.errorTheme ? Color.red5 : styles.color,
    })
  },
  valueContainer: (styles) => ({
    ...styles,
    fontSize: "14px",
    paddingLeft: "0",
    top: "5px",
  }),
  multiValue: (styles) => ({
    ...styles,
    marginBottom: "4px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    backgroundColor: Color.white,
    border: `1px solid ${Color.black}`,
    fontSize: "14px",
    padding: "5px",
    borderRadius: "2px 0 0 2px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    backgroundColor: Color.white,
    border: `1px solid ${Color.black}`,
    borderLeft: "0",
    fontSize: "14px",
    padding: "5px",
    borderRadius: "0 2px 2px 0",
    cursor: "pointer",
    ":hover": {
      backgroundColor: Color.black,
      color: Color.white,
    },
  }),
}

const Error = styled.span`
  font-size: 10px;
  line-height: 14px;
  color: red;
  display: block;
  margin-top: 7px;
  letter-spacing: 0.13px;
  max-width: 380px;
`;

const Inner = styled(UI.Inner)`
  padding: 120px 200px 100px;

  @media (${breakpoints().maxL}) {
    padding: 50px 120px;
  }
  @media (${breakpoints().maxM}) {
    padding: 50px 30px;
  }
`;

class Register extends Component {
  constructor() {
    super();
    this.state = {
      registrationStep: 1,
      firstStepButtonDisabled: true,
      accountVariant: null,
      registrationType: null,
      invalidFields: {
        account: false,
        user: false,
        company: false,
        agreements: false
      },
      invalidSelectFields: {},
      token: window.location.hash.includes("#rejestracja/") ? replace("#rejestracja/", "", window.location.hash) : "",
      selectedOptions: {},
    };
  }

  handleChange = (parent, field, moment = false) => event => {
    this.props.setValue(
      parent,
      field,
      moment ? event.format("DD-MM-YYYY") : event.target.type === "checkbox" ? event.target.checked : event.target.value
    );
  };

  handleChangeDate = (parent, field, isRequired) => value => {
    const { setValidation } = this.props;
    value && value.setHours(value.getHours() - value.getTimezoneOffset() / 60);
    value && setValidation(parent, field, this.isValid(null, value.toISOString(), "date", 0, 0, isRequired));
    this.props.setValue(parent, field, value);
  }

  isValid(fieldMap, value, type, min = 0, max = 245, isRequired = true) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const da = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;
    let pattern = fieldMap && fieldMap.pattern;

    if (pattern && type !== "date" && type !== "pesel")
      return isRequired || (!isRequired && value.length) ? new RegExp(pattern).test(value.toLowerCase()) : true;
    else if (type === "date")
      return isRequired || (!isRequired && !!value) ? da.test(value) : true;
    else if (type === "email")
      return isRequired || (!isRequired && !!value.length) ? re.test(String(value).toLowerCase()) : true;
    else if (type === "pesel")
      return isRequired || (!isRequired && !!value.length) ? !value || this.validatePesel(value) : true;
    else if (type === "options")
      return value.length > 0 || !isRequired;
    else
      return isRequired || (!isRequired && !!value) ? value.length >= min && value.length <= max : true;
  }

  validatePesel(pesel) {
    const reg = /^[0-9]{11}$/;
    if (reg.test(pesel) === false)
      return false;
    else {
      const digits = ("" + pesel).split("");
      if ((parseInt(pesel.substring(4, 6)) > 31) || (parseInt(pesel.substring(2, 4)) > 12))
        return false;

      let checksum = (
        1 * parseInt(digits[0]) +
        3 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        9 * parseInt(digits[3]) +
        1 * parseInt(digits[4]) +
        3 * parseInt(digits[5]) +
        7 * parseInt(digits[6]) +
        9 * parseInt(digits[7]) +
        1 * parseInt(digits[8]) +
        3 * parseInt(digits[9])) % 10;

      if (checksum === 0)
        checksum = 10;

      checksum = 10 - checksum;

      return (parseInt(digits[10]) === checksum);
    }
  }

  handleBlurValidation = (fieldMap, parent, fieldName, selectedOption) => event => {
    const { data } = this.props.state;
    const { setValidation } = this.props;
    const value = event.target.value || selectedOption || "";
    const source = data.sections.find(section => section.name === parent).fields.find(field => field.name === fieldName);

    setValidation(parent, fieldName, this.isValid(fieldMap, value, source.type, source.min, source.max, source.required));
  };

  handleCheckboxLabel = (parent, field, value) => {
    this.props.setValue(parent, field, !value);
  };

  handleChangeMaterialUIRadio = event => {
    const { setValidation } = this.props;

    this.props.setValue("account", "type", event.target.value);

    setValidation("account", "type", this.isValid(null, event.target.value, "options", 0, 0, true));
  };

  handleChangeOption = (parent, fieldName, isRequired) => selectedOption => {
    const { setValidation } = this.props;
    const simpleValue = selectedOption ? selectedOption.map((option) => option.value).join(",") : "";
    this.props.setValue(parent, fieldName, simpleValue);
    this.setState({
      selectedOptions: {
        ...this.state.selectedOptions,
        [fieldName]: selectedOption,
      }
    });

    setValidation(
      parent,
      fieldName,
      this.isValid(null, selectedOption || "", "options", 0, 0, isRequired),
    );
  };

  trySubmit = () => {
    const { data } = this.props.state;
    const { setSubmitted, sendData, setLoading } = this.props;

    setSubmitted();
    if (
      data.sections
        .map(section => section.fields.map(field => field.is_valid))
        .reduce((arr, val) => [...arr, ...val], [])
        .filter(val => val === false).length === 0
    ) {
      setLoading(true);
      sendData(this.state.token);
    } else {
      this.checkInvalidFields();
    }
  };

  checkInvalidFields() {
    const { data } = this.props.state;
    const invalidSection = data.sections.find(field => field.fields.find(item => !item.is_valid));
    if (invalidSection) {
      this.setState(prevState => ({
        invalidFields: {
          ...prevState.invalidFields,
          [invalidSection.name]: true
        }
      }));
    }
  }

  checkSelectFieldValidity = (field) => {
    this.setState({
      invalidSelectFields: {
        [field.label]: !field.value.length,
      },
    });
  }

  validateCalendar = () => {
    setTimeout(() => {
      const date = this.props.state.data.sections
        .find((section) => section.name === "company")?.fields
        .find((field) => field.type === "date")?.value;
      this.setState({ datePickerError: !date });
    }, 1);
  };

  setFirstStepButtonDisabled = (value) => this.setState({
    firstStepButtonDisabled: value,
  });

  componentDidMount() {
    this.props.getData(this.state.token);
    this.setState({
      lng: this.props.lng,
    });
  }

  componentDidUpdate() {
    if (!this.state.datePickerChanged) {
      this.setState({datePickerChanged: true});
    }
    if (i18n.language !== this.state.lng) {
      this.props.getData(this.state.token);
      this.setState({lng: i18n.language});
    }

    this.resetScrollingFlags();
  }

  resetScrollingFlags() {
    if (this.state.invalidFields.account || this.state.invalidFields.user
      || this.state.invalidFields.company || this.state.invalidFields.agreements) {
      this.setState({
        invalidFields: {
          account: false,
          user: false,
          company: false,
          agreements: false
        }
      });
    }
  }

  setRegistrationType = (registrationType) => {
    this.setState({
      registrationType,
    });
  };

  setAccountVariant = (accountVariant) => {
    this.props.setValue("account", "type", accountVariant);
    this.props.setValidation("account", "type", this.isValid(null, accountVariant, "options", 0, 0, true));
  };

  goToFirstStep = () => {
    this.setState({
      registrationStep: 1,
    });
  };

  handleNextStep = () => {
    this.setState({
      registrationStep: 2,
    });
  };

  render() {
    const { selectedOptions, invalidFields, registrationStep } = this.state;
    const { data, wasSubmitted, loading } = this.props.state || {};

    if (!data) {
      return null;
    }

    return !data.success ? (
      <Container>
        <UI.RegisterContainer>
          <a href="/">
            <UI.HeaderSVG>
              <LogoImg src={Logo} />
            </UI.HeaderSVG>
          </a>
          <ScrollIntoViewIfNeeded active={registrationStep === 2} options={{
            block: "start"
          }}>
            <></>
          </ScrollIntoViewIfNeeded>
          <ScrollIntoViewIfNeeded active={registrationStep === 1} options={{
            block: "start"
          }}>
            <></>
          </ScrollIntoViewIfNeeded>
          <UI.StepCounter>
            <UI.StepCounterTitle>{i18n.t("userRegistration.register.userRegistration")}</UI.StepCounterTitle>
            <UI.StepCounterText>
              {i18n.t("userRegistration.register.step")} {" "}
              {this.state.registrationStep} {" "}
              {i18n.t("userRegistration.register.of")} 2
            </UI.StepCounterText>
          </UI.StepCounter>
          <ContentWrapper>
            <UI.ContentInner $background="white">
              {registrationStep === 1 ? (
                <FirstStep
                  setAccountVariant={this.setAccountVariant}
                  setRegistrationType={this.setRegistrationType}
                  setButtonDisabled={this.setFirstStepButtonDisabled}
                />
              ) : (
                <Inner>
                  <ScrollIntoViewIfNeeded active={invalidFields.user} options={{
                    block: "start"
                  }}>
                    <UI.Row>
                      {data &&
                          data.sections &&
                          data.sections.filter(section => section.label.length > 0)
                            .filter(section => section.name !== "account")
                            .map((section) => (
                              <UI.Column key={section.name} width="calc(50% - 25px)" $flexDirection="column">
                                <ScrollIntoViewIfNeeded active={invalidFields.company} options={{
                                  block: "start"
                                }}>
                                  <UI.ListHeader>
                                    {section.label}
                                  </UI.ListHeader>
                                </ScrollIntoViewIfNeeded>

                                {section.fields.map((field, fieldindex) => {
                                  const type = field.type === "string" ? "text" : field.type;
                                  switch (type) {
                                  case "text":
                                  case "email":
                                  case "password":
                                    return (
                                      <React.Fragment key={fieldindex}>
                                        <Field key={fieldindex} className="tooltipParent">
                                          {field.additional_info && field.additional_info.description && (
                                            <Error>
                                              {field.additional_info.description}
                                            </Error>
                                          )}
                                          <Input
                                            label={field.label}
                                            placeholder={""}
                                            type={type}
                                            value={field.value}
                                            onChange={this.handleChange(section.name, field.name)}
                                            onBlur={this.handleBlurValidation(field, section.name, field.name)}
                                            disabled={!field.writable}
                                            error={
                                              field.touched || wasSubmitted
                                                ? !field.is_valid
                                                  ? true
                                                  : !this.isValid(field, field.value, type, field.min, field.max, field.required)
                                                    ? true
                                                    : undefined
                                                : undefined
                                            }
                                          />
                                          {field.is_valid === false && <Error>{field.reason}</Error>}
                                        </Field>
                                        {(section.name === "user" && fieldindex === section.fields.length - 1) && (
                                          <IconTable minVer="true" />
                                        )}
                                      </React.Fragment>
                                    );
                                  case "date":
                                    return (
                                      <Field key={fieldindex}>
                                        <UI.Label
                                          $errorTheme={field.required && (field.is_valid === false || this.state.datePickerError)}
                                          fontSize={11}
                                          color="black"
                                          $paddingTop="0"
                                          $paddingBottom="30"
                                          $marginTop="25"
                                        >
                                          {field.label}
                                          <UI.Date bottom="0">
                                            <DatePicker
                                              onChange={this.handleChangeDate(section.name, field.name)}
                                              onCalendarClose={this.validateCalendar}
                                              value={field.value ? new Date(field.value) : null}
                                              required={field.touched || wasSubmitted
                                                ? !field.is_valid
                                                  ? true
                                                  : !this.isValid(field, field.value, type, field.min, field.max, field.required)
                                                    ? true
                                                    : undefined
                                                : undefined}
                                              className="datePickerReact"
                                              calendarType="iso8601"
                                              returnValue="start"
                                              locale={i18n.language}
                                              dayPlaceholder={i18n.t("userRegistration.register.day")}
                                              monthPlaceholder={i18n.t("userRegistration.register.month")}
                                              yearPlaceholder={i18n.t("userRegistration.register.year")}
                                              format="d-M-y"
                                            />
                                          </UI.Date>
                                        </UI.Label>
                                        {field.is_valid === false && <Error>{field.reason}</Error>}
                                      </Field>
                                    );
                                  case "options":
                                    const isInvalid = field.is_valid === false
                                      && !this.isValid(field, field.value, type, field.min, field.max, field.required);
                                    const errorTheme = field.required && (field.is_valid === false || this.state.invalidSelectFields[field.label]);

                                    if (!selectedOptions.brands?.find((brand) => brand.label === "Inne - LeasinGo") && field.name === "brands_leasingo") {
                                      return null;
                                    }

                                    return (
                                      <Field key={fieldindex}>
                                        <UI.Label
                                          fontSize={11}
                                          color={Color.black}
                                          $paddingTop="0"
                                          $paddingBottom="0"
                                          $marginTop="25"
                                          $flexFlow="column"
                                          $alignItems="left"
                                          $isInvalid={isInvalid}
                                          $errorTheme={errorTheme}
                                        >
                                          {field.additional_info && field.additional_info.description}
                                          <Select
                                            key={fieldindex}
                                            value={field.allowed_options.length === 1 ? field.allowed_options[0] : selectedOptions[field.name]}
                                            placeholder={field.label}
                                            onChange={this.handleChangeOption(section.name, field.name, field.required)}
                                            onBlur={() => this.checkSelectFieldValidity(field)}
                                            options={field.allowed_options}
                                            closeMenuOnSelect={false}
                                            styles={selectStyles}
                                            isDisabled={field.allowed_options.length === 1}
                                            errorTheme={errorTheme}
                                            isMulti
                                            hideSelectedOptions
                                          />
                                        </UI.Label>
                                        {isInvalid && <Error>{field.reason}</Error>}
                                      </Field>
                                    );
                                  default:
                                    return (<Field key={fieldindex}>Unknown field type</Field>);
                                  }
                                })}
                              </UI.Column>
                            ))}
                    </UI.Row>

                    {data &&
                        data.sections &&
                        data.sections.filter(section => section.label.length === 0).map(section => (
                          <UI.FieldWrapper key={section} $flexDirection="column">
                            {section.additionalInfo?.administrator_info && (
                              <UI.CheckboxText
                                $onlyInfo
                                dangerouslySetInnerHTML={{
                                  __html: section.additionalInfo.administrator_info
                                }}
                                className="register-checkbox"
                              />
                            )}
                            {section.additionalInfo?.training_agreement && (
                              <UI.CheckboxText
                                $onlyInfo
                                dangerouslySetInnerHTML={{
                                  __html: section.additionalInfo.training_agreement
                                }}
                                className="register-checkbox"
                              />
                            )}
                            <ScrollIntoViewIfNeeded active={invalidFields.agreements} options={{
                              block: "start"
                            }}>
                              {section.fields.map((field, fieldindex) => (
                                <UI.Fieldline key={fieldindex}>
                                  <UI.CheckboxRegister
                                    checked={field.value}
                                    onChange={this.handleChange("agreements", field.name)}
                                    classes={{
                                      checked: "register-checked"
                                    }}
                                  />
                                  <UI.CheckboxText
                                    $error={wasSubmitted && field.is_valid !== null && !field.is_valid}
                                    dangerouslySetInnerHTML={{ __html: field.label }}
                                    onClick={() => {
                                      this.handleCheckboxLabel("agreements", field.name, field.value === true ? true : false);
                                    }}
                                    className="register-checkbox"
                                  />
                                </UI.Fieldline>
                              ))}
                            </ScrollIntoViewIfNeeded>
                          </UI.FieldWrapper>
                        ))}
                  </ScrollIntoViewIfNeeded>
                </Inner>
              )}
              <RegistrationFooter
                step={this.state.registrationStep}
                onNext={this.handleNextStep}
                goBack={this.goToFirstStep}
                signIn={this.trySubmit}
                firstStepButtonDisabled={this.state.firstStepButtonDisabled}
                loading={loading}
              />
            </UI.ContentInner>
          </ContentWrapper>
          <MainFooter/>
        </UI.RegisterContainer>
      </Container>
    ) : (
      <Container>
        {Theme.ModernEmailVerification ? (
          <Navigate to={Path.ConfirmEmail} />
        ) : (
          <RegistrationConfirm login={this.props.state.login || ""} />
        )}
      </Container>
    );
  }

  optionClicked(optionsList) {
    this.setState({ multiSelect: optionsList });
  }
  selectedBadgeClicked(optionsList) {
    this.setState({ multiSelect: optionsList });
  }
}

const mapStateToProps = state => ({ state: state.userRegistration });

const mapDispatchToProps = dispatch => ({
  getData: (token) => dispatch(UserRegistrationActions.getData(token)),
  setValue: (parent, field, value) => dispatch(UserRegistrationActions.setValue(parent, field, value)),
  setValidation: (parent, field, value) => dispatch(UserRegistrationActions.setValidation(parent, field, value)),
  setSubmitted: () => dispatch(UserRegistrationActions.setSubmitted()),
  sendData: (token) => dispatch(UserRegistrationActions.sendData(token)),
  setLoading: (value) => dispatch(UserRegistrationActions.setLoading(value)),
});

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(Register));
